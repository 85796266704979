.span-1 h3 {
	color: #000;
    font-size: 1.8em;	
}
p.in-para{
color: #B3B3B3;
    font-size: 0.9em;
    line-height: 2em;
    margin: 1em 0 ;
	border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
	padding:1em 0;
}
.price_single {
    padding-bottom: 1em;
}
span.reducedfrom {
	    color: #FAB005;
    font-size: 1.5em;   
}

h4.quick {
	color: #000;
	font-size:1.2em;
	text-transform: uppercase;
	margin-top: 1em;
	
}
p.quick_desc {
	color:#B3B3B3;
	font-size:0.9em;
	line-height: 2em;
	margin: 1em 0 2em;
}

/* Shutter In Vertical */
.my-cart-btn1 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
    background-color:#FAB005;
   color: #fff;
   padding: 0.5em 1em;
   font-size:1em;
    text-decoration:none;
	 border: none;
}
.my-cart-btn1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background:#029241;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
    border: none;
}
.my-cart-btn1:hover, .my-cart-btn1:focus, .my-cart-btn1:active {
	color: white;
	text-decoration:none;
	border:none;
	background: #FAB005;
	border-radius: 0;
}
.my-cart-btn1:hover:before, .my-cart-btn1:focus:before, .my-cart-btn1:active:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
   text-decoration:none;
}


ul.product-qty {
	padding: 0;
	list-style: none;
}
.product-qty span {
	color: #000;
	font-size:1.2em;
	padding-bottom: 0.5em;
	display: block;
	text-transform: uppercase;

}
.product-qty select {
	border: 1px solid #eee;
	padding: 0.5em;
	font-size: 1em;
	outline: none;
}
.product-qty option {
	border: 1px solid #EEE;
}
.wish-list {
	padding: 15px 0;
	border-bottom: 1px solid #f0f0f0;
	border-top: 1px solid #f0f0f0;
}
.wish-list ul{
	padding:0;
	list-style:none;
}
.wish-list li {
    display: inline-block;
    margin-right: 4%;
    width: 36%;
}
.wish-list li a i{
    margin-right:4%;
}
.wish-list li a {
	color:#FAB005;
	font-size: 0.85em;
	text-decoration: underline;
}
.wish-list li a span {
	padding-right:10px;
}
.wish-list li a:hover{
	text-decoration:none;
}

.size-top {
    margin: 2em 0;
}
/*----*/
.modal-dialog {
    width: 800px;
    margin: 30px auto;
}
.modal-spa {
    padding: 1em 1em 2.5em;
}

.modal-header {
    border-bottom: none;
}
.close {
    font-size: 31px;
}
/*--product--*/
/*--content-mid--*/

.col-md,.col-md1,.col-md2,.col-md3{
	position:relative;
}
.col-md1,.col-md2,.col-md3{
	text-align:center;
}
.big-sale {
    position: absolute;
    top: 77px;
    left: 20%;
    border: 4px double #fff;
    text-align: center;
    transform: rotate(-45deg);
    width: 210px;
    height: 210px;
}
.big-sale1 {
	transform: rotate(45deg);
	margin: 3.5em 0 0;
}
.big-sale h3,.big-sa h3,.big-sale2 h3,.big-sale3 h3{
	color:#fff;
	font-size:3em;
	margin-bottom: 0.3em;
}
.big-sa h3{
	margin-bottom: 0.2em;
}
.big-sale h3 span,.big-sa h3 span,.big-sale2 h3 span,.big-sale3 h3 span{
	color:#FAB005;
}
.big-sale p,.big-sa p,.big-sale2 p,.big-sale3 p{
	color:#fff;
	font-size:1em;
	line-height:2em;
}
.big-sa h6{
	color:#fff;
	font-size:1em;
	text-transform:uppercase;
	letter-spacing:2px;
}
.big-sa {
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 9em;
}
.big-sale2,.big-sale3{
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 3em;
}
.col-md2 {
    margin-bottom: 30px;
}
.content-mid {
    padding: 6em 0;
}
/*--content-mid--*/
.product {
	padding:8em 0 6em;
}
.pro-1 {
    margin-bottom: 2em;
}
/*--causel--*/
.carousel-indicators {
    position: absolute;
    bottom: -45px;
    left: 48%;
    z-index: 15;
	width: auto;
    padding-left: 0;
    margin: 0;
    text-align: center;
    list-style: none;
}


.carousel-indicators li {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin:0 6px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #029241;
    border: none;
    border-radius: 10px;
}
.carousel-indicators .active {
    background-color: #FAB005;
    margin: 0 6px;
}
.kic h3 {
    font-size: 2.5em;
    color: #000;
    margin: 0em 0.4em 1em;
}
.kic-top1 h6{
	font-size:1.3em;
	color:#000;
	margin:0.7em 0 0.3em;
}
.kic-top1 p{
	font-size:1em;
	color:#000;
}
.kic-top {
    padding: 8em 0 0;
}
.agileinf{
	padding-top:3em;
}
.seller-dashboard {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.dashboard-tabs button {
    margin: 5px;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
}

.dashboard-tabs button:hover {
    background-color: #ddd;
}

.dashboard-content {
    margin-top: 20px;
}

.add-product, .set-price, .sales-report {
    margin-top: 20px;
}

.add-product input, .set-price input, .add-product textarea {
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
}

.add-product button, .set-price button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-product button:hover, .set-price button:hover {
    background-color: #218838;
}

.sales-report table {
    width: 100%;
    border-collapse: collapse;
}

.sales-report th, .sales-report td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

/* General Styles */
body {
    font-family: Arial, sans-serif;
  }
  
  .kic-top {
    padding: 2em 0;
  }
  
  .kic h3 {
    text-align: center;
    margin-bottom: 1em;
  }
  
  .kic-top1 {
    text-align: center;
    margin-bottom: 1em;
  }
  
  .kic-top1 img {
    width: 100%;
    border-radius: 8px;
  }
  
  .kic-top1 h6 {
    margin-top: 1em;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .kic-top1 p {
    color: #666;
  }
  
  .product {
    padding: 2em 0;
    background-color: #f9f9f9;
  }
  
  .spec {
    text-align: center;
    margin-bottom: 2em;
  }
  
  .spec h3 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .spec .ser-t {
    margin: 1em 0;
  }
  
  .spec .ser-t b {
    display: block;
    height: 2px;
    background-color: #029241;
    width: 50%;
    margin: 1em auto;
  }
  
  .product-card {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 1em;
    margin-bottom: 1em;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-image-wrapper {
    position: relative;
  }
  
  .product-image {
    border-radius: 8px;
  }
  
  .mid-1 {
    padding: 1em 0;
  }
  
  .mid-2 p {
    margin: 0;
  }
  
  .mid-2 .item_price {
    color: #d9534f;
    font-size: 1.2em;
  }
  
  .add-to-cart-btn {
    background-color: #d9534f;
    border: none;
  }
  
  .add-to-cart-btn:hover {
    background-color: #c9302c;
  }
  
  .modal-content {
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .modal-header {
    border-bottom: 1px solid #ddd;
  }
  
  .modal-body {
    padding: 2em;
  }
  
  .modal-image {
    border-radius: 8px;
  }
  
  .quick {
    font-size: 1.2em;
    margin-top: 1em;
  }
  
  .quick_desc {
    margin-top: 0.5em;
    color: #666;
  }
  
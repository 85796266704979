.banner-top{
	text-align: center;
    width: 100%;
    height: 114px;
    display: block;
    /* background: url(../images/sh.jpg)no-repeat ; */
    padding: 3em 0;
    background-size: cover;
}
.banner-top h3{
	font-size:2.5em;
	color:#fff;
	float:left;
    font-weight: 700;
}
.banner-top h4{
	font-size:0.9em;
	color:#fff;
	margin-top:1em;
	float:right;
}
.banner-top h4 a{
	color:#FAB005;
}
.banner-top h4 label{
	margin:0 0.4em;
}
.faq-w3 {
    padding: 6em 0;
}
.panel-default {
    border-color: #FFF;
}
.panel-default>.panel-heading {
    color: #222;
    background-color: #FFFFFF;
    border-color: #FFF;
	text-transform:capitalize;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #FFF;
}
.panel-title {
    font-size: 1.5em;
    color: #5D5D5D;
}
.panel-title:hover {
     cursor: pointer;
}
.panel-title span {
	color:#ED0612;
}
.panel-body {
	padding-top:0;
	padding-bottom:0;
}
.panel-body p {
    color: #9A9A9A;
    font-size: 13.5px;
    margin-left: 17px;
    padding: 0px 15px 20px;
    line-height: 30px;
}
.panel-heading {
	padding: 20px 15px;
}
.panel-group .panel+.panel {
    margin-top: 0px;
}
.panel-group .panel {
    margin-bottom: 5px;
}
.terms h6 {
    font-size: 1.5em;
    color: #5D5D5D;
}
.terms h6 span{
         color: #ED0612;
}
.terms p {
    color: #999;
    font-size: 1em;
    line-height: 2em;
    margin-top: 15px;
    padding-left: 40px;
}
.terms ol li {
    color: #999;
    font-size:0.9em;
    font-weight: 300;
    line-height: 26px;
    margin-top: 15px;
}
.terms {
    padding: 6em 0;
}

.terms-bottom {
    margin-top: 3em;
}
.ter-wthree{
	margin-bottom:1em;
}
/*-- //terms-section-ends --*/